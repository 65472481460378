/*!
* https://github.com/YouCanBookMe/react-datetime
*/

.rdt {
  position: relative;
}

.rdt .rdtPicker {
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
  margin-top: -20px;
  visibility: hidden;
  display: block;
  opacity: 0;
}

.rdt.rdtOpen .rdtPicker {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.rdt input.form-control {
  border: 0;
  background-image: linear-gradient(#616161, #616161), linear-gradient(#888888, #888888);
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: rgba(0, 0, 0, 0);
  transition: background 0s ease-out;
  float: none;
  box-shadow: none;
  border-radius: 0;
  font-weight: 400;
  padding: 6px 0 7px;
  padding-top: 8px;
  font-size: 14px;
  font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.42857;
  display: block;
  width: 100%;
  color: #222;
}

.rdt input.form-control:focus {
  outline: none;
  background-image: linear-gradient(#616161, #616161), linear-gradient(#D2D2D2, #D2D2D2);
  background-size: 100% 2px, 100% 1px;
  box-shadow: none;
  transition-duration: 0.3s;
}

.rdtPicker {
  display: none;
  position: absolute;
  width: 260px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  border-radius: 0.125rem;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  min-width: 160px;
}

.rdtPicker:before {
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;
  vertical-align: middle;
  content: "";
  top: -5px;
  left: 10px;
  right: auto;
  color: #FFFFFF;
  border-bottom: .4em solid;
  border-right: .4em solid transparent;
  border-left: .4em solid transparent;
}

.rdtPicker:after {
  border-bottom: .4em solid #FFFFFF;
  border-right: .4em solid transparent;
  border-left: .4em solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  top: -5px;
  left: 10px;
}

.rdtPicker {
  display: block;
  top: 40px;
}

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
  padding: 5px;
  border-radius: 4px;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
  border-color: #fff !important;
  border-collapse: collapse;
}

.rdtPicker td, .rdtPicker th {
  text-align: center;
  padding: 1px;
}

.rdtPicker td {
  cursor: pointer;
}

.rdtDay {
  height: 30px;
  line-height: 33px;
  width: 30px;
  text-align: center;
  padding: 0px;
  border-radius: 50%;
}

.rdtDay.rdtToday.rdtActive, .rdtDay.rdtActive, .rdtDay.rdtActive:hover {
  background-color: #ff5f2e !important;
  color: #fff;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(156, 39, 176, 0.6);
}

.rdtDays tr .dow {
  border-bottom: 1px solid #E3E3E3;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: inherit;
  padding-bottom: 5px;
  padding-top: 10px;
}

.rdtDays tr .rdtOld, .rdtDays tr .rdtNew {
  color: #bdbdbd;
}

.rdtPicker td.rdtDay:hover, .rdtPicker td.rdtHour:hover, .rdtPicker td.rdtMinute:hover, .rdtPicker td.rdtSecond:hover, .rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}

.rdtPicker td.rdtToday {
  position: relative;
}

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}

.rdtPicker td.rdtDisabled, .rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}

.rdtPicker td span.rdtDisabled, .rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
}

.rdtPicker th.rdtSwitch {
  width: 50px;
  padding: 5px;
  border-radius: 4px;
}

.rdtPicker th.rdtNext, .rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
  border-radius: 50%;
  line-height: 33px;
}

.rdtPicker .dow, .rdtPicker th.rdtSwitch, .rdtPicker th.rdtNext, .rdtPicker th.rdtPrev, .rdtPicker .rdtTimeToggle {
  color: #616161;
}

.rdtPrev span, .rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled, .rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker thead tr:first-child th {
  cursor: pointer;
}

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}

.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth, td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}

td.rdtMonth:hover, td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters>div {
  float: left;
  width: 40px;
  font-weight: inherit;
  margin: 3px;
  border-radius: 50%;
}

.rdtCounters .rdtCounterSeparator {
  width: 0;
  border: 1px solid transparent;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounter .rdtCount {
  padding: 7px;
  border: 1px solid transparent;
}

.rdtCounters .rdtCounter:last-child .rdtCount {
  color: #616161;
}

.rdtCounterSeparator {
  padding: 7px;
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  line-height: 40px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  color: #eee;
  -webkit-transition: all 60ms ease-in;
  -moz-transition: all 60ms ease-in;
  -o-transition: all 60ms ease-in;
  -ms-transition: all 60ms ease-in;
  transition: all 60ms ease-in;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.rdtCounter .rdtBtn:hover {
  background: #eee;
  color: #797979;
}

.rdtCounter .rdtCount {
  font-size: inherit;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: inherit;
  margin-top: 37px;
}

.rdtMonths, .rdtYears {
  padding-bottom: 10px;
}

.rdtMonths .rdtMonth, .rdtMonths .rdtYear, .rdtYears .rdtMonth, .rdtYears .rdtYear {
  display: inline-block;
  width: 56px;
  height: 56px;
  line-height: 56px;
  margin: 3px 3px;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
}

.rdtMonths .rdtMonth.rdtActive, .rdtMonths .rdtYear.rdtActive, .rdtYears .rdtMonth.rdtActive, .rdtYears .rdtYear.rdtActive {
  background-color: #ff5f2e !important;
  color: #fff;
}